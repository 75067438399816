import {get} from 'lodash'
import moment from "moment";

export class UserTransformer {

    static transformRequest(data) {

        let user = {
            assureur_id: data.assureur_id,
            name: data.prenom + " " + data.nom,
            email: data.email,
            password: data.mdp,
            type_utilisateur: 'assureur',
            sous_domaine: data.service
        }
        let personne_physique = {
            civilite: data.civilite,
            nom: data.nom,
            prenom: data.prenom,
            date_naissance: data.date_naissance,
        }

        let moyenne_contact = {
            email: data.email,
            adresse: data.adresse,
            complement_adresse: data.adresse_secondaire,
            code_postal: data.postal,
            ville_id: data.ville,
            tel: data.phone,
        }

        return Object.assign({}, {user}, {personne_physique}, {moyenne_contact}, {user_id: data.user_id})
    }

    static transformResponse(item) {
        return {
            user_id: get(item, 'user.id', null),
            civilite: get(item, 'user.personne_physique.civilite', null),
            nom: get(item, 'user.personne_physique.nom', null),
            prenom: get(item, 'user.personne_physique.prenom', null),
            username: get(item, 'user.name', null),
            service: get(item, 'user.sous_domaine', null),
            date_naissance: moment(get(item, 'user.personne_physique.date_naissance', null), 'YYYY-MM-DD').format('DD/MM/YYYY'),
            phone: get(item, 'user.personne_physique.moyen_contact.tel', null),
            email: get(item, 'user.personne_physique.moyen_contact.email', null),
            adresse: get(item, 'user.personne_physique.moyen_contact.adresse', null),
            adresse_secondaire: get(item, 'user.personne_physique.moyen_contact.complement_adresse', null),
            postal: get(item, 'user.personne_physique.moyen_contact.code_postal', null),
            ville: get(item, 'user.personne_physique.moyen_contact.ville_id', null),
        }
    }

}
