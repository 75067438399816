<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <span class="h1 text-primary font-weight-bolder text-uppercase  my-0">Assureurs</span>
      </div>
    </div>
    <b-card class="mb-2">
    <b-overlay :show="showLoading" no-wrap />

     <b-tabs content-class="pt-2" >
             <b-tab  :title="`Partenaires`" active>
                   <Partenaires :partenaires="assureursPartenaires" />
             </b-tab>
             <b-tab  :title="`Liste des assureurs`" >
                   <All @fetchAssureursDetails="fetchAssureursDetails" :assureurs="assureursAll" />
             </b-tab>
     </b-tabs> 
    </b-card>
 </div>
</template>

<script>
import { BTab, BTabs,BCardText,BCard,BOverlay  } from 'bootstrap-vue'
import All from './assureursList/All.vue';
import Partenaires from './assureursList/Partenaires.vue';
export default {
  components: {
    BTab,
    BTabs,
    BCardText, BCard,All,Partenaires,BOverlay
  },
  props: {
    
  },
  data(){
    return{
      assureursPartenaires:[],
      assureursAll:[],
      showLoading:false
    }
  }
 ,
 created() {
     this.fetchAssureursDetails();
    },
 methods:{
    fetchAssureursDetails(){
        this.showLoading = true;
        this.$http.get('assureurs/assureurDetails').then((res)=>{
            this.assureursPartenaires = res.data.assureursPartenires
            this.assureursAll = res.data.allAssureurs;
        }).catch((err)=>{
            console.log(err)
        }).finally(() => {
            this.showLoading = false
          })
    }
 }
};
</script>