<template>
    <div>
      <b-sidebar id="sidebar-user" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarAssureurUser" shadow backdrop no-header right>
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Créer un utilisateur</h5>
  
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
  
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                <b-overlay :show="showLoading" no-wrap />

  
              <b-form-group label="Civilité" label-for="civilite">
                  <b-form-select id="civilite" v-model="$v.user.civilite.$model" >
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="(item, index) in civilite" :key="index" :value="item.value">
                      {{ item.text }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!$v.user.civilite.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Nom" label-for="nom">
                  <b-form-input class="text-uppercase" id="nom" v-model="$v.user.nom.$model" placeholder="Nom" />
                  <b-form-invalid-feedback :state="!$v.user.nom.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Prénom" label-for="prenom">
                  <b-form-input class="text-capitalize" id="prenom" v-model="$v.user.prenom.$model" placeholder="Prénom" />
                  <b-form-invalid-feedback :state="!$v.user.prenom.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Mot de passe" label-for="mdp">
                  <b-form-input type="password" id="mdp" v-model="$v.user.mdp.$model" placeholder="Mot de passe" />
                  <b-form-invalid-feedback :state="!$v.user.mdp.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                  <!-- <b-form-invalid-feedback :state="!$v.user.mdp.$error && !$v.user.mdp.valid">
                    le mot de passe doit contenir  :<br/>1 majuscule<br/>1 minuscule<br/>1 caractère spécial<br/>1 chiffre
                                                    <br/> 8 caractères
                  </b-form-invalid-feedback> -->
              </b-form-group>
  
              <b-form-group label="Confirmer Mot de passe" label-for="confirm">
                  <b-form-input type="password" id="confirm" v-model="$v.user.confirm_mdp.$model" placeholder="Confirmer Mot de passe" />
                  <!-- <b-form-invalid-feedback :state="!$v.user.confirm_mdp.$error" && !$v.user.confirm_mdp.required>
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="!$v.user.confirm_mdp.$error && !$v.user.confirm_mdp.sameAsPassword">
                    Veuillez renseigner un mot de passe valide
                  </b-form-invalid-feedback> -->
              </b-form-group>
  
              <b-form-group label="Nom utilisateur" label-for="utilisateur">
                  <b-form-input id="utilisateur" v-model="$v.user.username.$model" placeholder="Nom utilisateur" />
                  <b-form-invalid-feedback :state="!$v.user.username.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Date de naissance" label-for="date_naissance">
                  <flat-pickr id="date_naissance" v-model="$v.user.date_naissance.$model" class="form-control" placeholder="Date de naissance" :config="configDate"  />
                  <b-form-invalid-feedback :state="!$v.user.date_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group> 
  
  
              <b-form-group label="Service" label-for="service">
                  <b-form-select id="service" v-model="$v.user.service.$model" >
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="(item, index) in services" :key="index" :value="item">
                      {{ item }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!$v.user.service.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
  
  
              <b-form-group label="Téléphone" label-for="tel">
                  <b-form-input id="tel" v-model="$v.user.phone.$model"  placeholder="Téléphone" />
                  <b-form-invalid-feedback :state="!$v.user.phone.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Email" label-for="email">
                  <b-form-input id="email" v-model="$v.user.email.$model" placeholder="Email" />
                  <b-form-invalid-feedback :state="!$v.user.email.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Adresse" label-for="adresse">
                  <b-form-input id="adresse" v-model="$v.user.adresse.$model"  placeholder="Adresse" />
                  <b-form-invalid-feedback :state="!$v.user.adresse.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
  
              <b-form-group label="Complément Adresse" label-for="adresse_secondaire">
                  <b-form-input id="adresse_secondaire" v-model="user.adresse_secondaire" placeholder="Complément Adresse" />
              </b-form-group>
  
              <b-form-group label="Code Postal" label-for="postal">
                  <b-form-input id="postal" v-model="$v.user.postal.$model"  placeholder="Code Postal" />
                  <b-form-invalid-feedback :state="!$v.user.postal.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group>
              
  
              <b-form-group label="Ville" label-for="ville">
                  <b-form-select id="ville" v-model="$v.user.ville.$model">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="(item, index) in villes" :key="index" :value="item.id">
                      {{ item.ville }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!$v.user.ville.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s
                  </b-form-invalid-feedback>
              </b-form-group> 
  
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordAssureurUSer"> Enregistrer </b-button>
              </div>
            </b-form>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import { BAvatar, BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip,BOverlay } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import flatPickr from 'vue-flatpickr-component'
  import vSelect from 'vue-select'
  // eslint-disable-next-line import/named
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
  import {UserTransformer} from "../gestion-assureur/transformer/user.transformer";
  import {required,requiredIf,minLength,maxLength,sameAs,email} from "vuelidate/lib/validators";
  
  export default {
    components: {
      BButton,
      BSidebar,
      BFormGroup,
      BFormInput,
      BForm,
      BFormInvalidFeedback,
      BInputGroup,
      BInputGroupPrepend,
      BFormSelect,
      BFormSelectOption,
      BFormTextarea,
      BFormFile,
      BAvatar,
  
      flatPickr,BOverlay,
      vSelect,
  
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContentVue,
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      assureurid: {
        type: Number,
        default: null,
        required: true
      },
    },
    data() {
      return {
        configDate: { dateFormat: 'd/m/Y' },
        required,
        requiredIf,
        isUserObligatoire: false,
        user:{
          mode:'create',
          civilite:null,
          nom:null,
          prenom:null,
          mdp:null,
          confirm_mdp:null,
          username:null,
          date_naissance:null,
          service:null,
          phone:null,
          email:null,
          adresse:null,
          adresse_secondaire:null,
          postal:null,
          ville:null,
        },
        civilite: [
          {
              text: 'M.',
              value: 'MR'
          },
          {
              text: 'Mme.',
              value: 'MME'
  
          },
        ],
        services: ['assureur', 'inspecteur', 'marketing'],
        villes:[],
      showLoading: false,

      }
    },
    validations: {
      user: { 
        civilite: { required },
        nom:{ required },
        prenom:{ required },
        mdp: {
            // required: requiredIf(() => this.user.mode === "create"),
            minLength: minLength(8),
            valid: function(value) {
                return this.complexPassword(value);
            }
        },
        // confirm_mdp: {
        //     required: requiredIf(() => this.user.mode === "create"),
        //     sameAsPassword: sameAs('mdp')
        // },
        confirm_mdp:{ required },
        username:{ required },
        date_naissance:{ required },
        service:{ required },
        phone:{ required },
        email:{ required },
        adresse:{ required },
        postal:{ required },
        ville:{ required },
      },
    },
    watch: {
      'user.postal': {
        immediate: true,
        handler(val) {
          this.GetVille(val)
        }
      },
      typeSelected: {
        handler(val) {
        }
      }
    },
    created() {
  
    },
    methods: {
      complexPassword(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-+]/.test(value);
        return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
        );
      },
      // Methode additional
      hideSideBar() {
        this.$refs.sideBarAssureurUser.hide()
      },
      openToSelectFile: () => {
        document.getElementById('filecoformite').click()
      },
      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant
          }
        })
      },
      changeisUserObligatoire(val) {
        this.isUserObligatoire = !!val
      },
      clearData() {
        this.user.civilite = null
        this.user.nom = null
        this.user.prenom = null
        this.user.mdp = null
        this.user.confirm_mdp = null
        this.user.username = null
        this.user.date_naissance = null
        this.user.service = null
        this.user.phone = null
        this.user.email = null
        this.user.adresse = null
        this.user.adresse_secondaire = null
        this.user.postal = null
        this.user.ville = null
      },
  
      recordAssureurUSer() {
        this.showLoading = true;
        this.$v.$reset();
        this.$v.user.$touch();
        if (this.$v.user.$error) {
            return 0;
        }
      
        let data = Object.assign({},
          this.user,
          {assureur_id: this.assureurid}
        )
  
        this.$http
          .post('/assureurs/saveAssureurUser', UserTransformer.transformRequest(data))
          .then(res => {
            if (res.data) {
              this.hideSideBar()
              this.clearData()
              this.messageToast(
              "Le compte assureur crée avec succès",
              "Succès",
              "success"
            );
            }
          })
          .catch(err => {
            this.messageToast(
              "Erreur lors de changement de mot de passe.",
              "Erreur",
              "error"
            );
            console.error(err)
          }).finally(()=>{
            this.showLoading = false;
          })
        
  
      },
      GetVille(code) {
        if (code && code.length === 5) {
          this.$http
              .get(`/helpers/${code}/villes`)
              .then(res => {
                if (res.data?.length > 0) {
                  this.villes = [...res.data]
                  this.user.ville = this.villes[0].id
                }
              })
              .catch(err => {
                console.log(err)
              })
        } else {
          this.villes = []
        }
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  