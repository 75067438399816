<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="partenaires"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: false,
        selectionInfoClass: 'custom-class',
        selectionText: 'lignes sélectionnées',
        clearSelectionText: 'Effacer la sélection',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'id'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-toggle.sidebar-1
                @click="setAssureurId(props.row.id)"
              >
                <feather-icon icon="LockIcon" class="mr-50" />
                <span>Réinitialiser le mot de passe</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item v-b-toggle.sidebar-user @click="setAssureurId(props.row.id)" >
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          <span>Créer le compte assureur</span>
                        </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col md="6" lg="5" xxl="3">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Affichage 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} entrées </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div class="text-center align-middle" slot="emptystate">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
    <b-sidebar
      id="sidebar-1"
      ref="sideBarRenewPassword"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <template>
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Réinitialiser le mot de passe
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="RenewPassword">
          <b-form class="p-2"  @submit.prevent="RenewPassword(onSubmit)"  @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <!-- -------------------------- -->
            <b-form-group label="Le mot de passe*" label-for="new_password">
              <validation-provider
                #default="{ errors }"
                name="mot de passe"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new_password"
                    v-model="new_password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="new_password"
                    placeholder="Entre le mot de passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <!--  -->
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                  >{{ errors[0] }}</b-form-invalid-feedback
                >
              </validation-provider>
            </b-form-group>
            <!-- -------------------------- -->
            <b-button @click="RenewPassword" variant="primary" size="md">
              <span class="align-middle">Réinitialiser le mot de passe</span>
            </b-button>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <add-new-user-assureur   :assureurid="assureur_id || 0"   />

  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store/index";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import AddNewUserAssureur from '../assureurActions/AddNewUserAssureur.vue'
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BSidebar,
  VBToggle,
  BPagination,
  BRow,
  BCol,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormInput,
  BFormGroup,
  BForm,
  BFormSelect,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
export default {
  components: {
    // VGT
    VueGoodTable,
    // BV
    BPagination,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    VBToggle,
    BSidebar,
    BOverlay,
    BFormInput,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContentVue,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroup,AddNewUserAssureur
  },
  props: {
    partenaires: {
      type: Array,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      pageLength: 10,
      assureur_id: null,
      dir: false,
      searchTerm: "",
      columns: [
        {
          field: "denomination",
          label: "Dénomination",
          sortable: true,
          filterable: true,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
          filterOptions: { enabled: true, placeholder: "Dénomination" },
        },
        {
          field: "list_of_risques",
          label: "Listes des risques",
          sortable: true,
          filterable: true,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
          filterOptions: { enabled: true, placeholder: "Listes des risques" },
        },
        {
          field: "date_integration",
          label: "Date d'intégration",
          type: "date",
          filterable: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
          sortable: false,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
          filterOptions: {
            enabled: true,
            placeholder: "Date Création",
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: "identifiant",
          label: "Identifiant",
          sortable: true,
          filterable: true,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
          filterOptions: { enabled: true, placeholder: "Identifiant" },
        },
        {
          field: "id",
          label: "Actions",
          sortable: false,
          filterable: false,
          thClass:
            "border-0 text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
      ],
      new_password: null,
      showLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    RenewPassword() {
      this.showLoading = true;
      this.$http
        .post("assureurs/renewPassword", {
          new_password: this.new_password,
          id: this.assureur_id,
        })
        .then((res) => {
          if (res.data.success) {
            
            this.messageToast(
              "Le mot de passe a été changé avec succès",
              "Succès",
              "success"
            );
          } else {
            this.messageToast(
              "Erreur lors de changement de mot de passe.",
              "Erreur",
              "error"
            );
          }
        })
        .catch((err) => {
          this.messageToast(
            "Erreur lors de changement de mot de passe.",
            "Erreur",
            "error"
          );
        })
        .finally(() => {
          this.hide()
          this.showLoading = false;
        });
    },
    hide() {
      this.$refs.sideBarRenewPassword.hide();
      this.new_password = null;
      this.assureur_id = null;
    },
    setAssureurId(id) {
      this.assureur_id = id;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>